export function strBool(s: any) {
  if (typeof s === 'string') {
    s = s.toLowerCase();
  }
  if (
    s === 'y'
    || s === 'yes'
    || s === 'true'
    || s === true
    || s === '1'
    || s === 1
    || s === 'on'
  ) {
    return true;
  } else {
    return false;
  }
}