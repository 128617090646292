function portColonStr(port: number|null): string {
  return port ? `:${port}` : '';
}

const frontEndPort: number|null = process.env.VUE_APP_FRONT_END_PORT === "" ? null : Number(process.env.VUE_APP_FRONT_END_PORT);
const backEndPort: number|null = process.env.VUE_APP_BACK_END_PORT === "" ? null : Number(process.env.VUE_APP_BACK_END_PORT);
const frontEndUrl: string = process.env.VUE_APP_FRONT_END_URL + portColonStr(frontEndPort);
const backEndUrl: string = process.env.VUE_APP_BACK_END_URL + portColonStr(backEndPort);

const config = {
	http: {
		frontEndUrl,
		frontEndPort,
		backEndUrl,
		backEndPort
	}
};

export default config;