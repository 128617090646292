
import { defineComponent } from 'vue'
import pinia from './store'
import { storeToRefs } from 'pinia'
import { useUserStore } from './store/UserStore'
import config from './config'
import { RequiredScopes, scopesSatisfied } from '@/Authentication';
import { useTheme } from 'vuetify';

const navItems: Array<{title: string, icon: string, path: string, requiredScopes?: RequiredScopes}> = [
  {
    title: 'Home',
    icon: 'mdi-home',
    path: '/'
  },
  {
    title: 'Entry Portal',
    icon: 'mdi-printer-3d',
    path: '/self-populating-form?form_slug=in_vivo_print_form',
    requiredScopes: {
      '*': ['create']
    }
  },
  {
    title: 'Data',
    icon: 'mdi-database-outline',
    path: '/form-entry-data-table?form_slug=in_vivo_print_form',
    requiredScopes: {
      '*': ['read']
    }
  },
  {
    title: 'Admin',
    icon: 'mdi-wizard-hat',
    path: '/administration',
    requiredScopes: {
      '*': ['owner']
    }
  }
]

export default defineComponent({
  name: 'App',
  setup () {
    const userStore = useUserStore(pinia);
    const theme = useTheme();
    // read states from store
    const { isAuthorized, displayName, scopes, useDarkTheme } = storeToRefs(userStore)
    // make vars available to component
    return {
      isAuthorized,
      displayName,
      scopes,
      useDarkTheme,
      theme
    }
  },
  data () {
    return {
      drawerVisible: null as null | boolean,  // set to null to let Vue choose default depending on mobile vs desktop
      config,
      themeIcon: 'mdi-weather-sunny'
    }
  },
  computed: {
    /**
     * Return navItems that the user has sufficient scope to access.
     */
    items: () => {
      return navItems.filter((elem) => {
        return elem.requiredScopes ? scopesSatisfied(elem.requiredScopes) : true;
      });
    }
  },
  methods: {
    /**
     * Toggle between dark and light theme.
     */
    toggleTheme () {
      this.useDarkTheme = !this.useDarkTheme; // toggle it in user preference store for persistent setting
      this.theme.global.name.value = this.useDarkTheme ? 'dark' : 'light';
      this.updateThemeIcon();
    },
    /**
     * Update the theme icon.
     */
    updateThemeIcon() {
      this.themeIcon =  this.useDarkTheme ? 'mdi-weather-night' : 'mdi-weather-sunny'
    }
  },
  mounted() {
    // apply the user's theme preference upon mount
    this.theme.global.name.value = this.useDarkTheme ? 'dark' : 'light'
    this.updateThemeIcon();
  }
})
