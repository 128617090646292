import { createApp } from 'vue'
import store from './store'
import router from './router'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import './Authentication'
import axios from 'axios'

// have axios always include credentials when sending requests
axios.defaults.withCredentials = true;

// always serialize BigInts as strings when converting to JSON
(BigInt.prototype as any).toJSON = function () {
  return this.toString();
};

loadFonts()

// set up and mount the app
const app = createApp(App)
app.use(store)      // reactive store via Pinia
app.use(router)     // where routes are defined
app.use(vuetify)    // get Vuetify UI components
app.mount('#app')
