import axios from 'axios';
import pinia from './store'
import { useUserStore } from './store/UserStore'
import config from './config';

export type ScopeCategorySlug = '*' |
  'global' |
  'in_vivo_therapy_prints';

export type ScopeSlug = 'su' |
  'owner'  |
  'manage' |
  'read'   |
  'create';

const elevatedScopes: ScopeSlug[] = ['su', 'owner']

export type RequiredScopes = {[key in ScopeCategorySlug]?: ScopeSlug[]}

const userStore = useUserStore(pinia);

export const refreshUserStore = async () => {
  const loginInfo = await axios.get(`${config.http.backEndUrl}/login/info`, { withCredentials: true });
  console.log('loginInfo', loginInfo)
  userStore.$state.isAuthorized = loginInfo.data.isAuthorized;
  userStore.$state.displayName = loginInfo.data.user?.name ?? '';
  userStore.setAccessScopes(loginInfo.data.scopes ?? {});
  userStore.userId = loginInfo.data.user?.user_id ?? '';
}

export const scopesSatisfied = (
  requiredScopes: RequiredScopes
): boolean => {
  const userScopes = userStore.scopes;
  // check access for elevated permissions
  if (elevatedScopes.some(scope_slug => userScopes.global?.includes(scope_slug))) {
    return true;
  }
  // check access for explicitly defined permissions
  for (const [reqScopeCategorySlug, reqScopeSlugs] of Object.entries(requiredScopes)) {
    if (scopesSatisfiedInCategory(reqScopeCategorySlug as ScopeCategorySlug, reqScopeSlugs)) {
      return true;
    }
  }
  return false;
}

export const scopesSatisfiedInCategory = (
  reqScopeCategorySlug: ScopeCategorySlug|string,
  reqScopeSlugs: ScopeSlug[]
): boolean => {
  const userScopes = userStore.scopes;
  if (userScopes.global && ( userScopes.global.includes('su'))) {
    return true;
  }
  const checkCategories: ScopeCategorySlug[] = [reqScopeCategorySlug as ScopeCategorySlug, 'global'];
  for (const cat of checkCategories) {
    if (cat === '*') {
      for (const userScopeSlugs of Object.values(userScopes)) {
        if (anyScopeSlugSatisfied(reqScopeSlugs, userScopeSlugs)) {
          return true;
        }
      }
    } else {
      if (!(cat in userScopes)) {
        continue;
      }
      if (userScopes[cat] && anyScopeSlugSatisfied(reqScopeSlugs, userScopes[cat]!)) {
        return true;
      }
    }
  }
  return false;
}

const anyScopeSlugSatisfied = (
  reqScopeSlugs: ScopeSlug[],
  userScopeSlugs: ScopeSlug[]
): boolean => {
  return reqScopeSlugs.some(scopeSlug => userScopeSlugs.includes(scopeSlug));
}