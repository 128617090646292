import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import { RequiredScopes, ScopeCategorySlug, ScopeSlug } from '@/Authentication';

/**
 * This store is for user login state and preferences
 */
export const useUserStore = defineStore({
  id: 'userStore',
  state: () => ({
    isAuthorized: false,
    displayName: '',
    userId: '',
    scopes: {} as RequiredScopes,
    useDarkTheme: useStorage('useDarkTheme', false),
    tableItemsPerPage: useStorage('tableItemsPerPage', 10)
  }),
  actions: {
    /**
     * 
     * @param retrievedScopes `scopes` returned from the `/login/info` endpoint
     */
    setAccessScopes(retrievedScopes: any) {
      this.scopes = {};
      if (Array.isArray(retrievedScopes)) {
        for (const retrievedScope of retrievedScopes) {
          if (retrievedScope.scope_category_slug && retrievedScope.scope_slugs) {
            this.scopes[retrievedScope.scope_category_slug as ScopeCategorySlug] = retrievedScope.scope_slugs as ScopeSlug[];
          }
        }
      }
    }
  }
})