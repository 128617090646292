import { refreshUserStore, RequiredScopes, scopesSatisfied } from '@/Authentication'
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import pinia from '@/store'
import { useUserStore } from '../store/UserStore'
import HomeView from '../views/HomeView.vue'
import { strBool } from '@/utils/StrBool'
import { useTitle } from '@vueuse/core'

const siteName = 'Aspect Data Portal'
const formFullTitle = (pageTitle: string) => {
  return `${pageTitle} - ${siteName}`;
}

// an array of accessible routes
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: formFullTitle('Home') }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: { title: formFullTitle('Login') }
  },
  {
    path: '/print-params-entry',
    name: 'print-params-entry',
    component: () => import('../views/PrintParamsEntryView.vue'),
    meta: {
      title: formFullTitle('Print Parameter Input'),
      requiredScopes: {
        'in_vivo_therapy_prints': ['create']
      }
    },
  },
  {
    path: '/view-print-entries',
    name: 'view-print-entries',
    component: () => import('../views/ViewPrintEntriesView.vue'),
    meta: {
      title: formFullTitle('Print Entries'),
      requiredScopes: {
        'in_vivo_therapy_prints': ['read']
      }
    }
  },
  {
    path: '/administration',
    name: 'administration',
    component: () => import('../views/AdministrationView.vue'),
    children: [
      {
        path: '',
        redirect: '/administration/user-scope-management'
      },
      {
        path: 'user-scope-management',
        component: () => import('../views/admin/UserScopeManagementView.vue')
      },
      {
        path: 'form-json-builder',
        component: () => import ('../views/admin/FormJsonBuilderView.vue'),
        // meta: {
        //   title: formFullTitle('Form JSON Builder'),
        //   requiredScopes: {
        //     '*': ['su']
        //   }
        // }
      }
    ],
    meta: {
      title: formFullTitle('Administration'),
      requiredScopes: {
        '*': ['owner']
      }
    }
  },
  {
    path: '/self-populating-form',
    name: 'self-populating-form',
    component: () => import ('../views/SelfPopulatingFormView.vue'),
    meta: {
      title: formFullTitle('Self Populating Form Test'),
      requiredScopes: {
        '*': ['create']
      }
    }
  },
  {
    path: '/form-entry-data-table',
    name: 'form-entry-data-table',
    component: () => import ('../views/FormDataOverview.vue'),
    meta: {
      title: formFullTitle('Form Entry Data Table'),
      requiredScopes: {
        '*': ['read']
      }
    }
  },
  {
    path: '/form-entry-data-details',
    name: 'form-entry-data-details',
    component: () => import ('../views/FormDataDetails.vue'),
    meta: {
      title: formFullTitle('Form Entry Data Table'),
      requiredScopes: {
        '*': ['read']
      }
    }
  },
  {
    path: '/form-builder',
    name: 'form-builder',
    component: () => import ('../views/FormBuilderView.vue'),
    meta: {
      title: formFullTitle('Form Builder'),
      requiredScopes: {
        '*': ['manage']
      }
    }
  },
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

/**
 * Below, some of the "before guards" and "after hooks" are called.
 * Please consult the following list for the full navigation resolution flow:
 * https://router.vuejs.org/guide/advanced/navigation-guards.html#the-full-navigation-resolution-flow
 */

/**
 * The before guard is called whenever a navigation is triggered.
 * Here, it is being used to check the user's login state. They are redirected to 
 * the sign in page if they're not logged in.
 */
router.beforeEach(async (to, from, next) => {
  if (strBool(process.env.VUE_APP_SKIP_LOGIN_CHECK)) {
    console.log(`Skip login check`)
    next();
    return;
  }
  // make sure the user is authenticated
  const userStore = useUserStore(pinia);
  await refreshUserStore();
  const isLoggedIn = userStore.$state.isAuthorized;
  if (!isLoggedIn && to.name !== 'login') {
    next({name: 'login'});
  } else if (isLoggedIn && to.name === 'login') {
    next({name: 'home'});
  }

  // make sure the user has sufficient access scope
  if (
    to.meta?.requiredScopes &&
    !scopesSatisfied(to.meta.requiredScopes as RequiredScopes)
  ) {
    // TODO: show the user a descriptive permission error message instead of redir to home
    next({name: 'home'});
  }

  // user has passed all clearance checks
  next();
})

/**
 * The after hook is called after navigation is confirmed.
 * Here, it is used to set the page title.
 */
router.afterEach((to, from) => {
  if (typeof to.meta.title === 'string') {
    useTitle(to.meta.title)
  }
})

export default router
