
import { defineComponent } from 'vue'


export default defineComponent({
  name: 'HomePage',

  data () {
    return {}
  },
})
